<template>
  <div class="c-mask">
    <div class="c-mask-body">
      <div class="c-mask-title">
        <span>设备续租</span>
        <van-icon class="close-icon" name="cross" @click="$emit('close')" />
      </div>
      <div class="rent-list">
        <div class="rent-list-item" @click="activeIndex = index" :class="{ 'active': activeIndex === index }"
          v-for="(rent, index) in list" :key="index">
          <div class="rent-list-item-line1" :class="{ 'active': activeIndex === index }">
            <div class="t1">{{ rent.leaseMonthsName }}</div>
            <div class="t2"><span>&yen;</span>{{rent.monthlyRent * rent.leaseMonths}}</div>
          </div>
          <div class="rent-list-item-tip" :class="{ 'active': activeIndex === index }">{{ rent.leaseDaysGiveName }}</div>
          <div v-show="rent.status == 1" class="rent-item-hot">火热</div>
        </div>
      </div>
      <div class="tip-line">
        <div class="tip-line-l">到期：</div>
        <div class="tip-line-r">{{item.leaseEnd}}（{{item.lastDay > 0 ? item.lastDay + '天到期' : '已到期'}}）</div>
      </div>
      <div class="tip-line">
        <div class="tip-line-l">赠送：</div>
        <div v-if="list[activeIndex]" class="tip-line-r">{{list[activeIndex].leaseDaysGive}}天</div>
      </div>
      <div class="tip-line">
        <div class="tip-line-l">租金：</div>
        <div v-if="list[activeIndex]" class="tip-line-r">&yen;{{list[activeIndex].monthlyRent * list[activeIndex].leaseMonths}}</div>
      </div>
      <div @click="xz()" v-preventReClick class="btn-pay">支付</div>
    </div>
  </div>
</template>
<script>
// 续租弹窗
import { Icon } from 'vant';
import { rentConfig, orderRenew } from '@/api/rent.js'
import { findIndex } from 'lodash'
import { wxJsApi, wxJsPay } from '@/api/h5.js'
export default {
  components: { [Icon.name]: Icon },
  data() {
    return {
      list: [],
      activeIndex: 1,
      item: {}, // 主订单信息
      pro: {},
    }
  },
  created() {
    // 初始化租赁配置
    rentConfig({}).then(res => {
      console.log(res, 'rentConfig')
      if(res) {
        this.pro = res;
        this.list = res.rentConfig;
        this.activeIndex = findIndex(res.rentConfig, {isDefault: 1})
      }
    })
  },
  methods: {
    initData(item) {
      this.item = {...item};
    },
    xz() {
      const rentConfigId = this.list[this.activeIndex].id;
      // TODO  对接微信支付 更新 paymentOrderId
      wxJsApi({ url: window.location.href }).then(msg1 => {
        console.log(msg1, '------> jssdk api')
        const { monthlyRent, leaseMonths } = this.list[this.activeIndex];
        wxJsPay({ 
          price:  monthlyRent * leaseMonths * 100,
          channelCode: 'wx_pub',
          channelExtras: {
            openid: sessionStorage.getItem('openid')
          },
        }).then(msg => {
          console.log(msg, '------> jssdk pay')
          const displayContent = JSON.parse(msg.displayContent);
          console.log(displayContent, '------> displayContent')
          this.$wxpay(msg1, displayContent, (res) => {
            // 生成订单
            console.log('pay res----->', res)
            if(res === 'success') {
              orderRenew({rentConfigId, paymentOrderId: msg.paymentOrderId, id: this.item.id }).then(() => {
                this.$toast('成功');
                this.$router.push({path: '/rent/detail', query: { id: this.item.id }});
              }).catch(e => {
                this.$toast(e.msg);
              })
            }
          })
        })
      })            
    }
  }  
}
</script>

<style lang="less" scoped>
.c-mask {
  position: fixed;
  z-index: 9;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  // width: 100vw;
  // height: 100vh;
  background: rgba(0, 0, 0, .5);

  .c-mask-body {
    margin: 2rem auto 0;
    width: 6.73rem;
    height: 9.02rem;
    background: #FFFFFF;
    border-radius: .12rem;
    .btn-pay {
      margin: .4rem auto 0;
      width: 2.5rem;
      height: .77rem;
      background: #2B67D9;
      box-shadow: 0px .04rem .15rem -.08rem rgba(57,79,154,0.3);
      border-radius: .38rem;
      font-weight: 500;
      font-size: .27rem;
      color: #FFFFFF;
      text-align: center;
      line-height: .77rem;
    }
    .tip-line {
      display: flex;
      padding:.2rem 0 0 .38rem;
      font-weight: 400;
      font-size: .27rem;
      color: #666;
      line-height: .38rem;
      .tip-line-r {
        color: #010101;
      }
    }
    .rent-list {
      padding: 0 0 .18rem 0.38rem;
      gap: .25rem;
      display: flex;
      flex-wrap: wrap;

      .rent-list-item {
        position: relative;
        width: 1.83rem;
        height: 1.94rem;
        background: #EEEEEE;
        border-radius: .29rem;

        .rent-item-hot {
          position: absolute;
          top: -0.15rem;
          left: 0;
          width: .96rem;
          height: .46rem;
          background: url('../../accesst/rent/hot.png') no-repeat;
          background-size: .96rem .46rem;
          z-index: 1;
          font-weight: 400;
          font-size: .23rem;
          color: #FFFFFF;
          line-height: .46rem;
          text-align: center;
        }

        .rent-list-item-tip {
          padding-top: .12rem;
          font-weight: 400;
          font-size: .23rem;
          color: #2B67D9;
          text-align: center;
        }

        .rent-list-item-tip.active {
          color: #FFF;
        }

        .rent-list-item-tip-un.active {
          color: #FFF;
        }

        .rent-list-item-tip-un {
          text-align: center;
          padding-top: .12rem;
          font-weight: 400;
          font-size: .23rem;
          color: #868686;
        }

        .rent-list-item-line1.active {
          height: 1.29rem;
          background: #E9F3F5;
          border-radius: .27rem;
        }

        .rent-list-item-line1 {
          margin: .04rem;
          text-align: center;
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          color: #000000;

          .t1 {
            font-size: .27rem;
            padding-top: .3rem;
          }

          .t2 {
            font-size: .46rem;

            span {
              font-size: .27rem;
            }
          }
        }
      }

      .rent-list-item.active {
        background: #2B67D9;
      }
    }

    .c-mask-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: 500;
      font-size: .31rem;
      color: #000000;
      padding: .38rem .38rem .44rem;

      .close-icon {
        font-size: .29rem;
      }
    }
  }
}
</style>