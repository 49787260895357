<template>
  <div class="container"> 
    <div class="list-header">
      <van-icon @click="$router.back(-1)" class="back-top-icon" name="arrow-left" />
      <div class="header-title">订单详情</div>
      <div class="header-left">&nbsp;</div>
    </div>
    <div class="h85"></div>
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <div class="device-img">
        <img src="@/accesst/rent/device-icon.png" alt="" srcset="" />
        <div v-show="o.deviceSn" class="divc-sn">眼保仪：{{o.deviceSn}}</div>
      </div>
      <div class="h-btns">
        <div>
          <img @click="xuzu" src="@/accesst/rent/x-icon.png" alt="" srcset="" />
          <div>续租</div>
        </div>
        <div>
          <img @click="change('change')" src="@/accesst/rent/c-icon.png" alt="" srcset="" />
          <div>更换</div>
        </div>
        <div >
          <img @click="change()" src="@/accesst/rent/t-icon.png" alt="" srcset="" />
          <div>退租</div>
        </div>
      </div>
      <div class="o-l">
      <template v-for="item in o.subOrderList">
        <!-- 续租订单 -->
        <div v-if="item.leaseType === 4" :key="item.id" class="o-l-i">
          <div class="o-l-title">续租订单：{{ item.id }}</div>
          <div class="h19"></div>
          <div class="o-l-line">
            <span>续租时长：</span>{{ item.leaseStart }} - {{ item.leaseEnd }}（{{item.leaseMonths}}个月）
          </div>
          <div class="h19"></div>
          <div class="o-l-line">
            <!-- TODO 优化 -->
            <span>设备租金：</span>¥{{ item.leaseMonths * item.monthlyRent }}
          </div>
          <div class="h19"></div>
          <!-- <div class="o-l-line">
            <span>设备押金：</span>¥{{ item.deivceDeposit }}
          </div> -->
          <div class="o-l-line">
            <span>续租时间：</span>{{ item.createTime }}
          </div>
        </div>
        <!-- 退租订单 -->
        <div v-if="item.leaseType === 3" :key="item.id" class="o-l-i">
          <div class="o-l-title">退租订单：{{ item.id }}</div>
          <div class="h19"></div>
          <div class="o-l-line d-flex">
            <span style="min-width: fit-content;">退租原因：</span>
            <div>{{ item.returnReason }}</div>
          </div>
          <div class="h19"></div>
          <div class="o-l-line d-flex">
            <span>&nbsp;&nbsp;</span>
            <template v-if="item.returnUploadPics">
              <div class="img-list" v-for="i in item.returnUploadPics.split(',')" :key="i">
                <img :src="i" alt="" srcset="" />
              </div>
            </template>
            <template v-else>
              <div>&nbsp;</div>
            </template>
          </div>
          <div class="h19"></div> 
          <div class="o-l-line d-flex">
            <span style="margin-top: -0.72rem">退货单号：</span>
            <!-- ToDo 退货地址 -->
            <div>
              {{item._addr}}<br>{{item._name}}&nbsp;{{item._mobile}}<br>{{item.returnExpressOrder}}({{item.returnExpressName}})
            </div>
          </div>
          <div class="h19"></div>
          <div class="o-l-line">
            <span>退租时间：</span>{{ item.createTime }}
          </div>
          <div class="h19"></div> 
          <div class="o-l-line d-flex">
            <span>订单状态：</span> {{ item.statusName }}            
          </div>
          <div class="h19"></div>
          <div v-show="item.returnStr" class="o-l-line">
            <span>退款详情：</span>{{ item.returnStr }}
          </div>
        </div>
        <!-- 换租订单 -->
        <div v-if="item.leaseType === 2" :key="item.id" class="o-l-i">
          <div class="o-l-title">更换订单：{{ item.id }}</div>
          <div class="h19"></div>
          <div class="o-l-line d-flex">
            <span style="min-width: fit-content;">更换原因：</span>
            <div>{{ item.returnReason }}</div>
          </div>
          <div class="h19"></div>
          <div class="o-l-line d-flex">
            <span>&nbsp;&nbsp;</span>
            <template v-if="item.returnUploadPics">
              <div class="img-list" v-for="i in item.returnUploadPics.split(',')" :key="i">
                <img :src="i" alt="" srcset="" />
              </div>
            </template>
            <template v-else>
              <div>&nbsp;</div>
            </template>
          </div>
          <div class="h19"></div> 
          <div class="o-l-line d-flex">
            <span style="margin-top: -0.72rem">退货单号：</span>
            <!-- ToDo 退货地址 -->
            <div>
              {{item._addr}}<br>{{item._name}}&nbsp;{{item._mobile}}<br>{{item.returnExpressOrder}}({{item.returnExpressName}})
            </div>
          </div>
          <div class="h19"></div> 
          <div class="o-l-line d-flex">
            <span style="margin-top: -0.32rem">收货地址：</span>
            <div>
              {{ item.deliveryAddr }}<br>{{item.consignee}}：{{ item.phone}}
            </div>
          </div>
          <div class="h19"></div>
          <div class="o-l-line">
            <span>更换时间：</span>{{ item.createTime }}
          </div>
          <div class="h19"></div> 
          <div class="o-l-line d-flex">
            <span>订单状态：</span>
            <div>
              {{ item.statusName }}
              <template v-if="item.expressName"><br>{{ item.expressName }} {{ item.expressOrder }}</template>
            </div>
          </div>
          <div v-show="item.compensateDays" class="h19"></div> 
          <div v-show="item.compensateDays" class="o-l-line">
            <!-- todo 补充 -->
            <span>补偿租期：</span>{{item.compensateDays}}天
          </div>
        </div>
      </template>
      </div>
    </van-pull-refresh>
    <div class="h85"></div>
    <div class="rentModel" v-show="show">
      <rentModel ref="rentModel" @close="show=false"/>
    </div>
  </div>  
</template>

<script>
import { Icon, PullRefresh } from 'vant';
import { orderGet } from '@/api/rent'
import { forEach, split } from 'lodash';
import rentModel from './rentModel.vue';
import moment from 'moment'
export default {
  components: { [Icon.name]: Icon, rentModel, [PullRefresh.name]: PullRefresh },
  data() {
    return {
      o: {},
      show: false,
      refreshing: false,
    }
  },
  created() {
    this.getDetail();
  },
  methods: {
    getDetail() {
      orderGet({id: this.$route.query.id}).then(res => {
        // 续租弹窗使用
        res.lastDay = moment(res.leaseEnd).diff(moment(res.leaseStart), 'days');
        res.leaseEnd = moment(res.leaseEnd).format('yyyy-MM-DD');
        res.leaseStart = moment(res.leaseStart).format('yyyy-MM-DD');
        forEach(res.subOrderList, v => {
          v.leaseEnd = moment(v.leaseEnd).format('yyyy-MM-DD');
          v.leaseStart = moment(v.leaseStart).format('yyyy-MM-DD');
          v.createTime = moment(v.createTime).format('yyyy-MM-DD HH:mm:ss');
          // v-if="item.status === 31 || item.status === 32"
          // <!-- 退款中或已关闭展示此信息 -->
          // 退货订单文案
          v.returnStr = v.refundExplain
          // 退货地址 
          const returnAddress = split(v.returnAddress, ',')
          v._addr = returnAddress[0] || '-'
          v._name = returnAddress[1] || '-'
          v._mobile = returnAddress[2] || '-'
          // if (v.leaseType === 3 && v.status === 31 && v.status === 32) {
          //   if(!v.refundAmountSub) v.refundAmountSub = 0;
          //   if(!v.refundAmountAdd) v.refundAmountAdd = 0;
          //   const total = (v.deivceDeposit - v.refundAmountSub + v.refundAmountAdd).toFixed(1);
          //   const diffDay = moment(v.leaseEnd).diff(moment(v.leaseStart), 'days');
          //   const lastDay = diffDay > 0 ? diffDay : 0;        
          //   v.returnStr = `押金${v.deivceDeposit}元，剩余租期${lastDay}天，赠送${v.leaseDaysGive}天，合计剩余天数${v.leaseDaysGive > lastDay ? 0 : lastDay - v.leaseDaysGive}天，退款合计${total}元，预计7个工作日原路退回。`
          // }
        })
        console.log('order-detail----->', res)
        this.refreshing = false;
        this.o = res;
      }).catch(e => {
        this.refreshing = false;
      }) 
    },
    onRefresh() {
      this.refreshing = true;
      this.getDetail()
    },
    change(type) {
      if(type === 'change' && this.o.status != 30 && this.o.status != 31 && this.o.status != 32) { // 订单状态 不是退款中、已退款 可以 换租 
        this.$router.push({path: '/rent/change', query: { id: this.$route.query.id, type }});
      } else if(this.o.status != 30 && this.o.status != 31 && this.o.status != 32) { // 订单状态 不是 退回中、退款中、已退款 可以 退租
        this.$router.push({path: '/rent/change', query: { id: this.$route.query.id, type }});
      } else {
        this.$toast(`订单正在退租，无法续租或更换`);
      }
    },
    xuzu() {
      if(this.o.status != 30 && this.o.status != 31 && this.o.status != 32) {
        this.$refs.rentModel.initData(this.o);
        this.show = true;
      } else {
        this.$toast(`订单正在退租，无法续租或更换`)
      }
      
    },
  }
}
</script>

<style lang="less" scoped>
.container {
  width: 100vw;
  height: 100vh;
  background: #F7F7F7;
  overflow: auto;
  .o-l {
    padding: 0 .19rem;
    .d-flex {
      display: flex;
      align-items: baseline;
    }
    .img-list {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      img{
        width: 1.6rem;
        height: 1.1rem;
        margin-right: .17rem;
      }
    }
    .o-l-line {
      font-weight: 400;
      font-size: .27rem;
      color: #010101;
      span {
        width: 1.38rem;
        color: #666666;
      }      
    }
    .h19 {
      height: .19rem;
    }
    .o-l-title {
      font-weight: 500;
      font-size: .31rem;
      color: #000000;
    }
    .o-l-i {
      margin-top: .19rem;
      padding: .27rem .19rem;
      background: #FFFFFF;
      border-radius: .08rem;
    }
  }
  .h-btns {
    width: 7.50rem;
    height: 1.96rem;
    background: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: space-around;
    img{
      display: block;
      width: .77rem;
      height: .77rem;
      margin-bottom: .1rem;
    }
    font-weight: 400;
    font-size: .27rem;
    color: #000000;
    text-align: center;
  }
  .h85 {
    height: .85rem;
  }
  .device-img {
    position: relative;
    width: 7.5rem;
    height: 4.35rem;
    img {
      width: 7.5rem;
      height: 4.35rem;
    }
    .divc-sn {
      position: absolute;
      padding-top: 1.6rem;
      left: 0;
      bottom: 0;
      width: 7.5rem;
      height: .72rem;
      text-align: center;
      font-weight: 400;
      font-size: .27rem;
      color: #000;
      // background: rgba(0,0,0,.5);
    }
  }
  .list-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9;
    height: .85rem;
    padding: 0 .46rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #FFF;
    .back-top-icon {
      width: .3rem;
      font-size: .32rem;
      color: #000;
      font-weight: bolder;
    }
    .header-title {
      font-size: .31rem;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      color: #000000;
    }
    .header-left {
      display: flex;
      align-items: center;
      width: auto;
      font-family: SourceHanSansCN, SourceHanSansCN;
      font-weight: 400;
      font-size: .27rem ;
      color: #2B67D9;
      .order-icon {
        width: .32rem;
        height: .32rem;
        margin-right: .08rem;
      }
    }
  }
}
</style>